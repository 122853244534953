/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
$ngx-datatable-selected-active-background: cornflowerblue;
$ngx-datatable-selected-active-background-hover: cornflowerblue;
@import "../node_modules/@swimlane/ngx-datatable/index.css";
@import "../node_modules/@swimlane/ngx-datatable/themes/material.scss";
@import "../node_modules/@swimlane/ngx-datatable/assets/icons.css";

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

[contenteditable] {
-webkit-user-select: text;
user-select: text;
}

.align_center {
    align-self: center !important;
}

.no-padding {
    padding: 0px
}

// ion-input {
//     border: 1px solid black;
//     border-radius: 10px;
//     height: 45px;
//     font-size: 20px;
//     width: 50%;
//     margin-top: 20px !important;
//     text-align: left;
//     margin: auto;
// }


ion-split-pane {
    --side-max-width: 270px;
    --side-min-width: 270px;
    // --side-width: 350px;
}


// ion-grid{
//     ion-row {

//         ion-col {

//             display: flex;
//             align-items: center;

//         }

//         ion-col:last-of-type {             

//             div{                
//                 min-width: 50px;
//             } 
//         }
//     }
// }




// ION-HEADER
ion-toolbar {

    height: 100px;
    --background: cornflowerblue;
    display: flex;
    align-items: center;

    ion-title {

        font-weight: bold;
        // font-size: 45px;
        --color: white;

    }

    ion-menu-button {
        --color: white;
    }


    ion-back-button {
        --color: white;
        --icon-font-size: 30px;
    }

}


ion-alert {
    div.alert-wrapper {
        min-width: 50% !important;
        max-width: 80% !important;
    }
}

// ion-menu {
//     --width: 50px
// }


p.marker-text-header {
    font-weight: bold;
    display: inline;
    font-size: 16px;
}

p.marker-text-body {
    font-size: 16px;
    display: inline;
}

form ion-item > ion-label,form  ion-item ion-input,form  ion-item ion-select {
    font-size: 20px !important
}

ion-footer {
    ion-button.btn-footer{
        min-height:50px;
        font-weight: bold;
    }
    ion-button.btn-footer.btn-edit{
        --background:white;
        --color:cornflowerblue;
    }
}

form {

    
    ion-input,ion-select {
        min-height: 50px !important;
    }

    ion-input,ion-select,ion-toggle,ion-textarea {

        border-radius: 20px !important;
        border:2px solid rgb(190, 190, 190) !important;
        margin-top: 10px;
        // --padding-top: 15px;
        // --padding-bottom: 15px;
        // --padding-left: 15px;
        // --padding-right: 15px;

    }

    ion-select::part(text) {
        padding-left: 10px;
    }

    ion-item:has(ion-input,ion-select,ion-toggle,ion-textarea) > ion-label{
        font-weight: bold;
    }

    ion-label[aria-disabled=true]{
        
        opacity: 1 !important;
    }

    ion-input[aria-disabled=true],ion-select[aria-disabled=true],ion-toggle[aria-disabled=true],ion-textarea[aria-disabled=true],ion-toggle[aria-disabled=true]{
        
        opacity: 1 !important;
    }

    .native-input.sc-ion-input-md,.native-textarea.sc-ion-textarea-md {
        padding: 10px;
    }
    
    .native-input[disabled].sc-ion-input-md,.native-textarea[disabled].sc-ion-textarea-md,.item-interactive-disabled:not(.item-multiple-inputs) .sc-ion-label-md-h,.toggle-disabled{
            
        opacity: 1 !important;
    }
}